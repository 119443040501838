<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="/pta_logo.jpg" alt="PTA Logo" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Set your new password
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          {{ ' ' }}
          <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
            login
          </router-link>
        </p>
      </div>
      <form @submit.prevent="submit" class="mt-8 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
          </div>

          <div>
            <label for="repeat_password-address" class="sr-only">repeat_password address</label>
            <input v-model="repeat_password" type="password" id="repeat_password-address" name="repeat_password" autocomplete="repeat_password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Repeat password" />
          </div>
        </div>

<!--        <div class="flex items-center justify-between">-->
<!--          <div class="text-sm">-->
<!--            <router-link to="/forgot-password" class="font-medium text-indigo-600 hover:text-indigo-500">-->
<!--              Forgot your password?-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
            </span>
            Set password
          </button>
        </div>
        <error-label v-if="errors.length" :errors="errors"></error-label>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { LockClosedIcon } from '@heroicons/vue/solid'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import errorLabel from '../components/errorLabel'
import {mapState, mapActions} from 'vuex'


export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('auth', ['user']),
    errors() {
      let output = [];
      this.v$.$errors.forEach(e=>{
        output.push(e.$message)
      })
      return output;
    }
  },
  mounted() {
    console.log(this.$store.state.auth)
  },
  data() {
    return {
      repeat_password: null,
      password: null
    }
  },
  components: {
    LockClosedIcon,
    errorLabel
  },
  validations() {
    return {
      repeat_password: {
        required: {
          $validator: required,
          $message: 'Repeat password is required'
        },
        sameAs: {
          $validator: sameAs('password'),
          $message: 'Must be the same like password'
        },
      },
      password: {
        required: {
          $validator: required,
          $message: 'Password is required'
        },
        minLength: {
          $validator: minLength(8),
          $message: 'Your password must be at least 8 characters'
        }
      },
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      this.v$.$touch()
      if(!this.v$.$invalid) {
        let res = await this.axios.post('user/reset_password/', {
          old_password: this.$route.params.id,
          new_password: this.password
        })
        try {
          let resp = await this.login({email: this.$route.params.id.split("_")[1], password: this.password})
          this.$router.push('/')
        } catch (e) {
          if (e.response.status == 412) {
            this.$router.push(`/confirm/${this.$route.params.id}`)
          }
        }
      }
    }
  }
}
</script>